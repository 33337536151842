var today = new Date();
var date = null;

$(document).ready(function () {
    setFooterHeight();
});

function registerDatalayerEvent(datalayerEvent)
{
    var dataLayer = parent.window.dataLayer || [];
    dataLayer.push(datalayerEvent);
}

// Set correct footer height based on size.
function setFooterHeight() {
    var height = $('footer').height('auto').outerHeight();

    $('footer').css({
        height: height
    });

    $('.page-content').css({
        'min-height': 'calc(100% - ' + height + 'px)'
    });

    $('footer').css({
        visibility: "visible"
    }).animate({
        opacity: 1.0
    });
}

// Return correct date representation from a Future string parameter type 
function getDisplayDate(dateString)
{
    // Format is ddMMyyyyHHmm
    var date = moment(dateString).format("DD.MM.YY HH:mm");
    return date;
}

// Create a ul with validation messages
function CreateValidationList(validationMessages) {
    // Create the list element:
    var list = document.createElement('ul');

    for (var i = 0; i < validationMessages.length; i++) {
        // Create the list item:
        var item = document.createElement('li');

        // Set its contents:
        item.appendChild(document.createTextNode(validationMessages[i]));

        // Add it to the list:
        list.appendChild(item);
    }

    // Finally, return the constructed list:
    return list;
}


function filterDisabledDates(datesObj) {
    disabledDates = [];
    datesObj.forEach(function (dateObj) {
        // If a date has less than 30 minutes available, disable the date.
        if (dateObj.AvailableMinutes < 30) {
            disabledDates.push(new Date(dateObj.Date).addHours(3));
        }
    });
    return disabledDates;
}

function disabledDatesCallback(data, status) {
    // Get disabled dates
    var disabledDates = filterDisabledDates(data);

    // Update datepicker with disabled dates
    $datePicker.datepicker('setDatesDisabled', disabledDates);
}

Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
};

function displayAvailableHours() {
    $('#appointmentSelector').show();
}

function displayConfirmation(e) {
    // Add time to confirmation
    $('.selected-appointment').html(getDisplayDate(e.target.dataset.time));
    $('#startTime').val(e.target.dataset.time);

    // Display the confirmation form
    $('.time-picker-list').slideUp(200, "swing", function () {
        $('.time-picker-confirm').slideDown(200, "swing");
        $('.appointment-confirmation').slideDown(200, "swing");
        $('.show-on-confirm').slideDown(200, "swing");
    });
}

function hideConfirmation() {
    // Display the time picker again
    $('.show-on-confirm').slideUp(200, "swing");
    $('.appointment-confirmation').slideUp(200, "swing", function () {
        $('.appointment-picker').slideDown(200, "swing");
    });
}

function getLoaderHtml() {
    return '<div class="spinner-container"><i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i></div>';
}

function redirectUrl(url) {
    event.preventDefault();
    window.location.href = url;
}