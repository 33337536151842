//
// All resize actions should be called from this timer so we don't have several running.
// First part is the timer it self, which runs continously to check if the browser has been resized, and gives it 200ms before it fires the resize methods
//


// Timer variables
var rtime;
var timeout = false;
var delta = 200;

// Variables to check if the window actually changed, or if the event was fired by $('.flexslider').resize();
var oldWindowSize = 0;
var newWindowSize = 0;

// Timer that will check if the resizing is "done" or not. If "done", run methods listed in resizeDone() method. This timer will prevent the resize evenet to fire continously when while resizing, becuase it waits until the user is hopefully done.
function resizeEnd() {
    if (new Date() - rtime < delta) {
        setTimeout(resizeEnd, delta);
    } else {
        timeout = false;
        // The actual moving of the cart
        newWindowSize = window.innerWidth;
        // Resize flexsliders if window size actually changed, don't do anything if it was just an inner element changing size.
        if (newWindowSize !== oldWindowSize) {
            // Set the oldWindowsSize.
            oldWindowSize = newWindowSize;

            resizeDone();
        }
    }
}

// Hook up to the resize event and start the timer
$(document).ready(function () {
    $(window).resize(function () {
        rtime = new Date();
        if (timeout === false) {
            timeout = true;
            setTimeout(resizeEnd, delta);
        }
    });
});

//
// Method that will call all our sub methods when screen has been resized
//
function resizeDone() {

    //
    // LIST ALL FUNCTIONS THAT SHOULD BE FIRED WHEN RESIZE IS DONE HERE
    //

    setFooterHeight();

}